.step-item {
  flex: 1;
  position: relative;
}

.step-line {
  position: absolute;
  top: 15px; /* Alinha no centro da bolinha */
  left: 50%;
  width: 100%;
  height: 2px;
  z-index: 0;
}

.step-item:last-child .step-line {
  display: none;
}
