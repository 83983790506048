.dropzone {
  border: 2px dashed #ced4da !important;
  padding: 30px;
  text-align: center;
  height: 200px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s, border-color 0.3s;
}

.dropzone.active {
  background-color: #e9ecef !important;
  border-color: #007bff !important;
  color: #007bff !important;
}
